<template>
  <section class="bg-surfaceSaturated py-25 theme-transition">
    <div class="mx-auto max-w-[90dvw]">
      <div
        class="flex flex-row lg:flex-col justify-start h-full w-full m-auto items-center mt-[86px]"
      >
        <!-- Text Content -->
        <div class="items-start">
          <h1
            class="text-4xl min-md:text-5xl font-bold text-primary text-center"
          >
            OPTIMIZE YOUR SPACE AND IMPROVE YOUR LOGISTICS
          </h1>
          <p class="text-2xl md:text-xl text-secondary my-6 text-center">
            Tailored storage solutions designed to meet <br />your business
            needs
          </p>
          <router-link
            to="/sign-up"
            class="block mx-auto w-fit border border-brand-yellow bg-brand-darkblue mt-5 px-5 py-3 rounded-lg text-white hover:text-brand-yellow focus:text-brand-yellow !duration-0 !transition-none"
            aria-label="Sign up now to optimize your logistics"
            role="button"
          >
            <span class="font-medium">Sign up Now</span>
          </router-link>
        </div>

        <!-- Image -->
        <div
          class="flex flex-col justify-center items-center w-auto max-w-full h-full md:mt-6"
        >
          <picture>
            <source
              type="image/avif"
              sizes="(max-width: 479px) 90vw, (max-width: 767px) 43vw, (max-width: 991px) 46vw, 48vw"
              srcset="
                images/Warehouse-p-500.avif   500w,
                images/Warehouse-p-800.avif   800w,
                images/Warehouse-p-1080.avif 1080w,
                images/Warehouse-p-1600.avif 1600w,
                images/Warehouse-p-2000.avif 2000w,
                images/Warehouse-p-2600.avif 2600w,
                images/Warehouse-p-3200.avif 3200w,
                images/Warehouse.avif        4000w
              "
            />
            <source
              type="image/webp"
              sizes="(max-width: 479px) 90vw, (max-width: 767px) 43vw, (max-width: 991px) 46vw, 48vw"
              srcset="
                images/Warehouse-p-500.webp   500w,
                images/Warehouse-p-800.webp   800w,
                images/Warehouse-p-1080.webp 1080w,
                images/Warehouse-p-1600.webp 1600w,
                images/Warehouse-p-2000.webp 2000w,
                images/Warehouse-p-2600.webp 2600w,
                images/Warehouse-p-3200.webp 3200w,
                images/Warehouse.webp        4000w
              "
            />
            <img
              src="images/Warehouse.png"
              alt="Warehouse image"
              width="681"
              height="456"
              class="w-full"
              loading="eager"
            />
          </picture>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HeroSection",
};
</script>

<style scoped></style>
